import './tree-col.module.scss';
import React, { Component } from 'react';
/* eslint-disable-next-line */
export interface TreeColProps {
  title?: string;
  content?: string;
  subTitle?: string;
  iconImage?: string;
  iconAlt?: string;
}

class TreeCol extends Component<TreeColProps> {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { title, iconImage, content, iconAlt } = this.props;
    return (
      <div className="tree-row">
        {iconImage && (
          <div className="tree-col">
            <div className="tree-ico">
              <img src={iconImage} alt={iconAlt} />
            </div>
          </div>
        )}
        <div className="tree-dt">
          {title}
          {content}
        </div>
      </div>
    );
  }
}

export default TreeCol;
